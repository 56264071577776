import { toUpper } from "lodash";
import React, { useState } from "react";
import Moment from "react-moment";
import 'moment-timezone';
import { useSelector } from "react-redux";
import orders from "../../api/orders";
import { ReactComponent as NavIcon } from "../../assets/images/heebeeIcon.svg";

const objectToArray = (obj) => {
  const keys = Object.keys(obj);
  const values = Object.values(obj);
  let arr = [];
  keys.forEach((key, i) => {
    let val = values[i];
    if (Array.isArray(val) === false) val = val.split(', ');
    let desc = "";
    val.forEach((obj, index) => {
      if (index === val.length - 1) desc += obj.value;
      else desc += (obj.value + ", ");
    })
    let obj = {
      title: key,
      desc: desc
    };
    arr.push(obj);
  })
  return arr;
}
export const CheckoutBill = ({ order, componentref, orderData }) => {
  if (order.length === 0) order = orderData.order_items;
  const state = useSelector(state => state);
  // console.log(orderData, "afterbilldata");
  // console.log(orderData);
  // const [name, setName] = useState("");
  // console.log(`/customer_search?mobile_no=${orderData?.customer_no}`);
  // orders.get(`/customer_search?mobile_no=${orderData?.customer_no}`, {
  //   headers: {
  //     token: state.userLogin.userInfo.token
  //   }
  // }).then(response => {
  //   // console.log(response);
  //   if (response.data.data !== undefined && response.data.data[0] !== undefined) {
  //     setName(`${response.data.data[0].first_name} ${response.data.data[0].last_name}`);
  //   }
  // })

  const userDetailFromStore = useSelector((state) => state.userDetails);
  const { user: userData } = userDetailFromStore;
  const userDataList = userData || [];
  return (
    <>
      <div ref={componentref} className="px-[10px]">
        <div className="flex flex-row  justify-between items-center my-4">
          <NavIcon className="w-20 h-20 py-2 px-2 " />
          <div >
            <p className="text-sm">{orderData?.order_id}</p>
            <p className="text-sm">{orderData?.branch_name}</p>
            <Moment tz="Asia/Kolkata" format="MMM D, YYYY hh:mm a" className="text-sm">{orderData?.createdAt}</Moment>
          </div>
        </div>
        <div className="flex justify-end">
          <h2 className="font-bold text-xs">Status: &nbsp; </h2>
          <h2 className="capitalize text-xs">{orderData.status}</h2>
        </div>
        <div className="flex justify-end">
          <h2 className="font-bold text-xs">Cashier: &nbsp; </h2>
          <h2 className="capitalize text-xs">{userDataList?.full_name}</h2>
        </div>
        <div className="flex justify-end">
          <h2 className="font-bold text-xs">Customer Name: &nbsp; </h2>
          <h2 className="capitalize text-xs">{orderData.customer_name}</h2>
        </div>
        {
          orderData.customer_name !== "Guest" &&
          <div className="flex justify-end">
            <h2 className="font-bold text-xs">Customer No.: &nbsp; </h2>
            <h2 className="capitalize text-xs">{orderData?.customer_no}</h2>
          </div>
        }
        <div className="flex justify-end">
          <h2 className="font-bold text-xs">Wallet Balance: &nbsp; </h2>
          <h2 className="capitalize text-xs">{parseFloat(orderData?.account_id) - parseFloat(orderData?.wallet_amount)}</h2>
        </div>

        <div className="flex flex-row w-full border-b-2 border-dotted border-black py-2 my-1 text-sm ">
          <div className="flex basis-1/2 font-bold justify-start">ITEM</div>
          <div className="flex basis-1/4 font-bold justify-center">QTY</div>
          <div className="flex basis-1/4 font-bold justify-end">SUBTOTAL</div>
        </div>
        {order.map((order_item, i) => {
          let adds = objectToArray(order_item.add_ons);
          return (
            <div className="flex flex-row text-xs text-gray-900 " key={i}>
              <div className="basis-1/2">
                <div className="mb-1">
                  <h2 className=" text-gray-900 capitalize  cursor-pointer font-bold">
                    {toUpper(order_item.product_name)}
                  </h2>
                  <div className=" flex flex-row  text-gray-900  leading-tight  capitalize">
                    {adds.map((addon, index) => <span className="mr-1" key={index}>{addon.desc}{(index !== (adds.length - 1)) && ', '}</span>)}{order_item.comment === '' ? '' : ', ' + order_item.comment}
                    {/* {Object.entries(order_item.add_ons).map(([key, val], i) => (
                      <span className="mr-2 text-gray-900" key={key}>
                        {val}
                        {i !== Object.entries(order_item.add_ons).length - 1
                          ? ", "
                          : ""}
                      </span>
                    ))} */}
                  </div>
                </div>
              </div>
              <div className="flex basis-1/4 justify-center font-bold">
                {order_item?.quantity || 1}
              </div>
              <div className="flex basis-1/4 justify-end font-bold">
                ₹{Math.round(((order_item?.price + order_item?.add_ons_price) * order_item?.quantity) * 100) / 100}
              </div>
            </div>
          );
        })}
        <div className="flex flex-col border-y-2 border-dotted border-black py-2 my-2 ">
          <div className="flex flex-row justify-between font-thin text-xs">
            <div className="text-gray-900 font-bold">SUBTOTAL</div>
            <div className="font-bold">
              ₹{Math.round(orderData?.sub_total * 100) / 100}
            </div>
          </div>
          {/* <div className="flex flex-row justify-between font-thin text-sm">
            <div className="text-gray-900 font-bold">MEMBERSHIP DISCOUNT</div>
            <div className="font-bold">
              ₹{Math.round(orderData?.discount * 100) / 100}
            </div>
          </div> */}
          <div className="flex flex-row justify-between font-thin text-xs">
            <div className="text-gray-900 font-bold">COUPON DISCOUNT</div>
            <div className="font-bold">
              ₹{Math.round(orderData?.discount * 100) / 100}
            </div>
          </div>
          {orderData?.membership_discount && (
            <div className="flex flex-row justify-between font-thin text-xs">
              <div className="text-gray-900 font-bold uppercase">Membership discount</div>
              <div className="font-bold">
                ₹{Math.round(orderData?.membership_discount * 100) / 100}
              </div>
            </div>
          )}
          {(orderData?.membership_discount || orderData?.discount) && (
            <div className="flex flex-row justify-between font-thin text-xs">
              <div className="text-gray-900 font-bold uppercase">Total Discount %</div>
                <div className="font-bold">
                  {Math.round(((parseFloat(orderData?.membership_discount) || 0) + (parseFloat(orderData?.discount) || 0)) / parseFloat(orderData?.sub_total) * 100)}%
                </div>
            </div>
          )}
          <div className="flex flex-row justify-between font-thin text-xs">
            <div className="text-gray-900 font-bold">SGST</div>
            <div className="font-bold">
              ₹{Math.round(orderData?.sgst * 100) / 100}
            </div>
          </div>
          <div className="flex flex-row justify-between font-thin text-xs">
            <div className="text-gray-900 font-bold">CGST</div>
            <div className="font-bold">
              ₹{Math.round(orderData?.cgst * 100) / 100}
            </div>
          </div>
          {orderData?.order_type === "Delivery" && (
            <div className="flex flex-row justify-between font-thin text-xs">
              <div className="text-gray-900 font-bold">ONLINE DELIVERY CHARGES</div>
              <div className="font-bold">
                ₹{Math.round(orderData?.delivery_charges * 100) / 100}
              </div>
            </div>
          )}
          <div className="flex flex-row justify-between font-thin text-xs">
            <div className="text-gray-900 font-bold">GRAND TOTAL</div>
            <div className="font-bold">
              ₹
              {orderData?.payment_method === "card + cash"
                ? Math.round(orderData?.card_amount * 100) / 100 +
                Math.round((orderData?.cash_amount || 0) * 100) / 100
                : Math.round(orderData?.paid_price * 100) / 100}
            </div>
          </div>
          {/* <div className="flex flex-row justify-between font-thin text-sm">
            <div className="text-gray-900 font-bold">TOTAL PAID</div>
            <div className="font-bold">
              ₹
              {Math.round(orderData?.paid_price + orderData?.change * 100) /
                100}{" "}
            </div>
          </div> */}
          <div className="flex flex-row justify-between font-thin text-xs border-t-2 border-dotted border-black pt-2 mt-1">
            <div className="text-gray-900 font-bold">PAYMENT METHOD</div>
            <div className="font-bold uppercase">
              {orderData?.payment_method}{" "}
            </div>
          </div>
          {orderData?.payment_method === "card" && (
            <div className="flex flex-row justify-between font-thin text-xs">
              <div className="text-gray-900 font-bold">CARD AMOUNT</div>
              <div className="font-bold">
                ₹{Math.round(orderData?.card_amount * 100) / 100 || 0}{" "}
              </div>
            </div>
          )}
          {orderData?.payment_method === "wallet" && (
            <div className="flex flex-row justify-between font-thin text-xs">
              <div className="text-gray-900 font-bold">WALLET AMOUNT</div>
              <div className="font-bold">
                ₹{Math.round(orderData?.wallet_amount * 100) / 100 || 0}{" "}
              </div>
            </div>
          )}
          {orderData?.payment_method === "cash" && (
            <div className="flex flex-row justify-between font-thin text-xs">
              <div className="text-gray-900 font-bold">CASH AMOUNT</div>
              <div className="font-bold">
                ₹{Math.round(orderData?.cash_amount * 100) / 100 || 0}{" "}
              </div>
            </div>
          )}
          {orderData?.payment_method === "card + cash" && (
            <div>
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">CASH AMOUNT</div>
                <div className="font-bold">
                  ₹{Math.round(orderData?.cash_amount * 100) / 100 || 0}{" "}
                </div>
              </div>
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">CARD AMOUNT</div>
                <div className="font-bold">
                  ₹{Math.round(orderData?.card_amount * 100) / 100 || 0}{" "}
                </div>
              </div>
            </div>
          )}
          {orderData?.payment_method === "wallet + cash" && (
            <div>
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">WALLET AMOUNT</div>
                <div className="font-bold">
                  ₹{Math.round(orderData?.wallet_amount * 100) / 100 || 0}{" "}
                </div>
              </div>
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">CASH AMOUNT</div>
                <div className="font-bold">
                  ₹{Math.round(orderData?.cash_amount * 100) / 100 || 0}{" "}
                </div>
              </div>
            </div>
          )}
          {orderData?.payment_method === "wallet + card" && (
            <div>
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">WALLET AMOUNT</div>
                <div className="font-bold">
                  ₹{Math.round(orderData?.wallet_amount * 100) / 100 || 0}{" "}
                </div>
              </div>
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">CARD AMOUNT</div>
                <div className="font-bold">
                  ₹{Math.round(orderData?.card_amount * 100) / 100 || 0}{" "}
                </div>
              </div>
            </div>
          )}
          {orderData?.payment_method === "cash" && (
            <>
              {/* <div className="flex flex-row justify-between font-thin text-xs border-t-2 border-dotted border-black pt-2 mt-1">
                <div className="text-gray-900 font-bold">CASH IN</div>
                <div className="font-bold">
                  ₹{Math.round(order.paid_price * 100) / 100}{" "}
                </div>
              </div> */}
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">CHANGE</div>
                <div className="font-bold">
                  ₹{Math.round(orderData.change * 100) / 100}{" "}
                </div>
              </div>
            </>
          )}
          <div className="flex flex-row justify-between font-thin text-xs border-t-2 border-dotted border-black pt-2 mt-1">
            <div className="font-bold">{orderData?.comment} </div>
          </div>
          <div className="flex flex-row justify-between font-thin text-xs">
            <div className="text-gray-900 font-bold">ORDER TYPE</div>
            <div className="font-bold">{orderData?.order_type} </div>
          </div>
        </div>
        <div className="font-bold self-center my-2 grid place-content-center">
          -------- ****** --------
        </div>
        <div className="text-xs self-center text-center">
          <h6 className="font-bold mb-0">
            Heebee coffee private limited
          </h6>
          <h6 className="font-bold mb-1">
            <small>Subject to Ludhiana Jurisdiction</small>
          </h6>
          <h6>
            <span className="font-bold">GSTIN </span>03AAFCH0428K1ZV
          </h6>
          <br />
          <div className="text-xs m-2 self-center p-2 text-center font-extrabold">
            <span>*</span>Thanks for choosing us! We appreciate the opportunity
            to serve you.<span>*</span>
          </div>
        </div>
      </div>
    </>
  );
};

const BillOrderCartItem = ({ order, componentref }) => {
  const userDetailFromStore = useSelector((state) => state.userDetails);
  const state = useSelector(state => state);
  const { user: userData } = userDetailFromStore;
  const userDataList = userData || [];
  // console.log(order);
  const [name, setName] = useState("");
  // orders.get(`/customer_search?mobile_no=${order?.customer_no}`, {
  //   headers: {
  //     token: state.userLogin.userInfo.token
  //   }
  // }).then(response => {
  //   // console.log(response);
  //   if (response.data.data !== undefined && response.data.data[0] !== undefined) {
  //     setName(`${response.data.data[0].first_name} ${response.data.data[0].last_name}`);
  //   }
  // })

  // console.log({ order, componentref });
  return (
    <>
      <div ref={componentref} className="px-[10px]">
        <div className="flex flex-row  justify-between items-center my-4">
          <NavIcon className="w-20 h-20 py-2 px-2 " />
          <div>
            <p className="text-sm">{order.order_id}</p>
            <p className="text-sm">{order.branch_name}</p>
            <Moment tz="Asia/Kolkata" format="MMM D, YYYY hh:mm a" className="text-sm">{order.createdAt}</Moment>
          </div>
        </div>
        <div className="flex justify-end">
          <h2 className="font-bold text-xs">Status: &nbsp; </h2>
          <h2 className="capitalize text-xs">{order.status}</h2>
        </div>
        <div className="flex justify-end">
          <h2 className="font-bold text-xs">Cashier: &nbsp; </h2>
          <h2 className="capitalize text-xs">{userDataList?.full_name}</h2>
        </div>
        <div className="flex justify-end">
          <h2 className="font-bold text-xs">Customer Name: &nbsp; </h2>
          <h2 className="capitalize text-xs">{order.customer_name}</h2>
        </div>
        {
          order.customer_name !== "Guest" &&
          <div className="flex justify-end">
            <h2 className="font-bold text-xs">Customer No.: &nbsp; </h2>
            <h2 className="capitalize text-xs">{order?.customer_no}</h2>
          </div>
        }
        <div className="flex flex-row w-full border-b-2 border-dotted border-black py-2 my-1 text-sm">
          <div className="flex basis-1/2 font-bold justify-start">ITEM</div>
          <div className="flex basis-1/4 font-bold justify-center">QTY</div>
          <div className="flex basis-1/4 font-bold justify-end">SUBTOTAL</div>
        </div>
        {order.order_items.map((order_item, i) => {
          let adds = objectToArray(order_item.add_ons);
          return (
            <div className="flex flex-row text-xs text-gray-900" key={i}>
              <div className="basis-1/2">
                <div className="mb-1">
                  <h2 className=" text-gray-900 capitalize  cursor-pointer  font-bold">
                    {toUpper(order_item.product_name)}
                  </h2>
                  <div className=" flex flex-row  text-gray-900  leading-tight  capitalize">
                    {adds.map((addon, index) => <span className="mr-1" key={index}>{addon.desc}{(index !== (adds.length - 1)) && ', '}</span>)}{order_item.comment === '' ? '' : ', ' + order_item.comment}
                    {/* {Object.entries(order_item.add_ons).map(([key, val], i) => (
                      <span className="mr-2 text-gray-900" key={key}>
                        {val}
                        {i !== Object.entries(order_item.add_ons).length - 1
                          ? ", "
                          : ""}
                      </span>
                    ))} */}
                  </div>
                </div>
              </div>
              <div className="flex basis-1/4 justify-center  font-bold">
                {order_item?.quantity || 1}
              </div>
              <div className="flex basis-1/4 justify-end  font-bold">
                ₹{Math.round(((order_item.price + order_item.add_ons_price) * order_item.quantity) * 100) / 100}
              </div>
            </div>
          );
        })}
        <div className="flex flex-col border-y-2 border-dotted border-black py-2 my-2 ">
          <div className="flex flex-row justify-between font-thin text-xs">
            <div className="text-gray-900 font-bold">SUBTOTAL</div>
            <div className="font-bold">
              ₹{Math.round(order.sub_total * 100) / 100}
            </div>
          </div>
          <div className="flex flex-row justify-between font-thin text-xs">
            <div className="text-gray-900  font-bold">COUPON DISCOUNT</div>
            <div className="font-bold">
              ₹{Math.round(order.discount * 100) / 100}
            </div>
          </div>
          {order?.membership_discount && (
            <div className="flex flex-row justify-between font-thin text-xs">
              <div className="text-gray-900 font-bold uppercase">Membership discount</div>
              <div className="font-bold">
                ₹{Math.round(order?.membership_discount * 100) / 100}
              </div>
            </div>
          )}
          {(order?.membership_discount || order?.discount) && (
            <div className="flex flex-row justify-between font-thin text-xs">
            <div className="text-gray-900 font-bold uppercase">Total Discount %</div>
              <div className="font-bold">
                {Math.round(((parseFloat(order?.membership_discount) || 0) + (parseFloat(order?.discount) || 0)) / parseFloat(order?.sub_total) * 100)}%
              </div>
            </div>
          )}
          <div className="flex flex-row justify-between font-thin text-xs">
            <div className="text-gray-900 font-bold">SGST</div>
            <div className="font-bold">
              ₹{Math.round(order.sgst * 100) / 100}
            </div>
          </div>
          <div className="flex flex-row justify-between font-thin text-xs">
            <div className="text-gray-900 font-bold">CGST</div>
            <div className="font-bold">
              ₹{Math.round(order.cgst * 100) / 100}
            </div>
          </div>
          {order?.order_type === "Delivery" && (
            <div className="flex flex-row justify-between font-thin text-xs">
              <div className="text-gray-900 font-bold">ONLINE DELIVERY CHARGES</div>
              <div className="font-bold">
                ₹{Math.round(order?.delivery_charges * 100) / 100}
              </div>
            </div>
          )}
          <div className="flex flex-row justify-between font-thin text-xs">
            <div className="text-gray-900 font-bold">GRAND TOTAL</div>
            <div className="font-bold">
              ₹{Math.round(order.paid_price * 100) / 100}
            </div>
          </div>
          {/* <div className="flex flex-row justify-between font-thin text-sm">
            <div className="text-gray-900 font-bold">TOTAL PAID</div>
            <div className="font-bold">
              ₹{Math.round((order.paid_price + order.change) * 100) / 100}
            </div>
          </div> */}
          <div className="flex flex-row justify-between font-thin text-xs border-t-2 border-dotted border-black pt-2 mt-1">
            <div className="text-gray-900 font-bold">PAYMENT METHOD</div>
            <div className="font-bold capitalize">{order?.payment_method} </div>
          </div>
          {order?.payment_method === "card" && (
            <div className="flex flex-row justify-between font-thin text-xs">
              <div className="text-gray-900 font-bold">CARD AMOUNT</div>
              <div className="font-bold">
                ₹{Math.round(order?.card_amount * 100) / 100 || 0}{" "}
              </div>
            </div>
          )}
          {order?.payment_method === "wallet" && (
            <div className="flex flex-row justify-between font-thin text-xs">
              <div className="text-gray-900 font-bold">WALLET AMOUNT</div>
              <div className="font-bold">
                ₹{Math.round(order?.wallet_amount * 100) / 100 || 0}{" "}
              </div>
            </div>
          )}
          {order?.payment_method === "cash" && (
            <div className="flex flex-row justify-between font-thin text-xs">
              <div className="text-gray-900 font-bold">CASH AMOUNT</div>
              <div className="font-bold">
                ₹{Math.round(order?.cash_amount * 100) / 100 || 0}{" "}
              </div>
            </div>
          )}
          {order?.payment_method === "card + cash" && (
            <div>
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">CASH AMOUNT</div>
                <div className="font-bold">
                  ₹{Math.round(order?.cash_amount * 100) / 100 || 0}{" "}
                </div>
              </div>
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">CARD AMOUNT</div>
                <div className="font-bold">
                  ₹{Math.round(order?.card_amount * 100) / 100 || 0}{" "}
                </div>
              </div>
            </div>
          )}
          {order?.payment_method === "wallet + cash" && (
            <div>
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">WALLET AMOUNT</div>
                <div className="font-bold">
                  ₹{Math.round(order?.wallet_amount * 100) / 100 || 0}{" "}
                </div>
              </div>
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">CASH AMOUNT</div>
                <div className="font-bold">
                  ₹{Math.round(order?.cash_amount * 100) / 100 || 0}{" "}
                </div>
              </div>
            </div>
          )}
          {order?.payment_method === "wallet + card" && (
            <div>
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">WALLET AMOUNT</div>
                <div className="font-bold">
                  ₹{Math.round(order?.wallet_amount * 100) / 100 || 0}{" "}
                </div>
              </div>
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">CARD AMOUNT</div>
                <div className="font-bold">
                  ₹{Math.round(order?.card_amount * 100) / 100 || 0}{" "}
                </div>
              </div>
            </div>
          )}
          {order?.payment_method === "cash" && (
            <>
              {/* <div className="flex flex-row justify-between font-thin text-xs border-t-2 border-dotted border-black pt-2 mt-1">
                <div className="text-gray-900 font-bold">CASH IN</div>
                <div className="font-bold">
                  ₹{Math.round(order.paid_price * 100) / 100}{" "}
                </div>
              </div> */}
              <div className="flex flex-row justify-between font-thin text-xs">
                <div className="text-gray-900 font-bold">CHANGE</div>
                <div className="font-bold">
                  ₹{Math.round(order.change * 100) / 100}{" "}
                </div>
              </div>
            </>
          )}
          <div className="flex flex-row justify-between font-thin text-xs border-t-2 border-dotted border-black pt-2 mt-1">
            <div className="font-bold">{order.comment} </div>
          </div>
          <div className="flex flex-row justify-between font-thin text-xs">
            <div className="text-gray-900 font-bold">ORDER TYPE</div>
            <div className="font-bold">{order.order_type} </div>
          </div>
        </div>
        <div className="font-bold self-center my-2 grid place-content-center">
          -------- ****** --------
        </div>
        <div className="text-xs self-center text-center">
          <h6 className="font-bold mb-0">
            Heebee coffee private limited
          </h6>
          <h6 className="font-bold mb-1">
            <small>Subject to Ludhiana Jurisdiction</small>
          </h6>
          <h6>
            <span className="font-bold">GSTIN </span>03AAFCH0428K1ZV
          </h6>
          <br />
          <div className="text-xs m-2 self-center p-2 text-center font-extrabold">
            <span>*</span>Thanks for choosing us! We appreciate the opportunity
            to serve you.<span>*</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillOrderCartItem;
