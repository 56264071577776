import React, { Fragment, useRef, useCallback, useEffect, forwardRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import AddMoneyInvoice from "./AddMoneyInvoice";

const AddmoneyBill = ({
    isPrint,
    setIsMoneyOpen,
    amount,
    setIsPrint,
    customer_detail,
    paymentMethod,
    applyPromoCode,
}) => {
      const componentref = useRef();
      const handlePrint = useReactToPrint({
        content: () => componentref.current,
      });
      const OrderBill = forwardRef((props, ref) => {
        return (
          <>
            <div className="flex flex-col w-[20rem] overflow-hidden bg-white px-2">
              <AddMoneyInvoice amount={amount} customer_detail= {customer_detail} componentref={ref} paymentMethod={paymentMethod} applyPromoCode={applyPromoCode}/>
            </div>
          </>
        );
      });

      const handleClick = () => {
        window.location.href = "/";
      };

    let closeButtonRef = useRef(null);

    return (
        <Transition appear show={isPrint} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 "
                style={{ zIndex: "67" }}
                initialFocus={closeButtonRef}
                onClose={() => {setIsPrint(false)
                    window.location.href = "/";
                }}
            >
                <div ref={closeButtonRef} className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black/20 backdrop-blur-[3px] w-full " />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="w-[90%] max-w-[350px] p-6 overflow-hidden align-middle transition-all transform text-center bg-white shadow-xl rounded-2xl absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-55%]">
                            <Dialog.Title className="text-center text-xl font-medium mb-4 text-gray-700">
                                Print Receipt
                            </Dialog.Title>
                            <div className="flex flex-col justify-center mt-4 gap-2">
                                <button
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-slate-500 bg-slate-100 border border-transparent rounded-[10px] hover:bg-slate-200 md:w-full w-full"
                                    onClick={handlePrint}
                                >
                                    Print Invoice
                                </button>
                                <button
                                    style={
                                        { background: "#664d03" }
                                    }
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white border border-transparent rounded-[10px] hover:bg-completeColor md:w-full w-full"
                                    onClick={() => {
                                        handleClick();
                                    }}
                                >
                                    Return home
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
                <div className="hidden">
                    <OrderBill amount={amount} ref={componentref} />
                </div>
            </Dialog>
        </Transition>
    );
}

export default AddmoneyBill;
