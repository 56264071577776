import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Moment from "react-moment";
import 'moment-timezone';
import Notification from "../layout/Notification";
import Sidebar from "../layout/Sidebar";
import OnHoldItemsCard from "./OnHoldItemsCard";
import CancelModal from "./CancelModal";
import Loader from "./Loader";
import orders from "../../api/orders";
import { connect, useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from "../../actions/orderListAction";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import store from "../../store";
import { get } from "lodash";
import OnHoldCheckoutModal from "./OnHoldCheckoutModal";
import { useNavigate } from "react-router-dom";
import { removeAllItemsFromCart } from "../../actions/cartAction";
import { useAppContext } from "../../context/AppContext";
import {
  LoginIcon,
  DocumentTextIcon,
  CurrencyRupeeIcon,
} from "@heroicons/react/outline";
import CountUp from "react-countup";
import { AiOutlinePrinter } from "react-icons/ai";
import { useReactToPrint } from "react-to-print";
import LogoutReportInvoiceWithToken from "./LogoutReportInvoiceWithToken";
import SoldProductReport from "./SoldProductReport";

const getSingleLogoutReport = async (employee_id, branch_id, log_in, log_out) => {
  const url = `${process.env.REACT_APP_BASE_URL}get_single_employee_logout_report_v2`;

  const token = get(store.getState(), "userLogin.userInfo.token");
  const config = {
    headers: {
      token: token,
    },
  };
  try {
    const response = await axios.post(url, { employee_id, branch_id, log_in, log_out }, config);
    if (response.data.data === "Token does not match") {
      sessionStorage.clear();
      window.location.href = "/login";
    }
    return response.data.data;
  } catch (error) { }
};

const LogoutReportsDetailsDisplay = (props) => {
  const dispatch = useDispatch();
  const { employee_id, branch_id, log_in, log_out, showLoader, hideLoader } = props;
  const [isOpenMainSidebar, setIsOpenMainSidebar] = useState(false);
  const [singleLogoutReport, setSingleLogoutReport] = useState({});
  const [done, setDone] = useState(undefined);
  const componentref = useRef();
  const componentref2 = useRef();
  const [soldData, setSoldData] = useState();
  const [isDisabled, setIsDisabled] = useState(true);

  const SoldReport =async () => {
    try {
      // eslint-disable-next-line no-unused-vars
      const data = await orders
        .post(
          `fetch_sold_product_report`,{
            start_date : log_in, end_date : log_out, branch_id: branch_id, employee_id: employee_id
          }
        )
        .then((res) => {
          setSoldData(res.data.data);
          setIsDisabled(false);
        });
    } catch (e) {
    }
  };

  useEffect(() => {
    setIsDisabled(true);
    SoldReport();
  }, [employee_id, branch_id, log_in, log_out]);

  const handleSoldPrint = useReactToPrint({
    content: () => componentref2.current,
  });


  const navigate = useNavigate();
  let data;

  useEffect(() => {
    showLoader();
    setTimeout(() => {
      hideLoader();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [log_in]);

  const ToggleMainSidebar = () => {
    setIsOpenMainSidebar(!isOpenMainSidebar);
  };


  useEffect(() => {
    setTimeout(() => {
      if (log_in) {
        getSingleLogoutReport(employee_id, branch_id, log_in, log_out).then((data) => {
          setSingleLogoutReport(data);
          setDone(true);
        });
      }
    }, 1000);
    setDone(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee_id, branch_id, log_in, log_out]);
  data = singleLogoutReport;
  //console.log(singleLogoutReport);

  const handlePrint = useReactToPrint({
    content: () => componentref.current,
  });

  return (
    <>
      <div className="hidden">
        <LogoutReportInvoiceWithToken
          componentref={componentref}
          data={data}
          orderData={singleLogoutReport[0]}
        />
      </div>
      <div className="hidden">
        <SoldProductReport
          componentref={componentref2}
          soldData={soldData}
          login= {log_in}
          logout= {log_out} 
          user_info={{
            branch_name : singleLogoutReport[0]?.branch_name,
            employee_name : singleLogoutReport[0]?.employee_name
          }}
        />
      </div>
      {!done ? (
        <div className="right-section w-full  h-[calc(100%_-_50px)] relative bg-stone-200">
          <div></div>
          <Loader />
        </div>
      ) : (
        <section className="right-section w-full  h-[calc(100%_-_50px)] relative bg-stone-200">
          <div className="flex justify-between items-center bg-stone-50 borderRadius rounded-[10px] p-1 m-2">
            <div
              className="p-2 bg-primaryLightColor25 rounded-[10px] text-primaryColor cursor-pointer"
              onClick={ToggleMainSidebar}
            >
              <MenuAlt2Icon className="w-6 h-6" />
            </div>

            <h1 className="font-bold text-2xl text-gray-700">
              <Moment tz="Asia/Kolkata" className="text-gray-700" format="MMM D, hh:mm a">{singleLogoutReport[0].login_in_time}</Moment> - <Moment tz="Asia/Kolkata" className="text-gray-700" format="MMM D, hh:mm a">{singleLogoutReport[0].logout_in_time}</Moment>
            </h1>

            <Notification />
          </div>

          <div className="h-[calc(100vh_-_80px)] overflow-auto">
            <Sidebar
              isOpenMainSidebar={isOpenMainSidebar}
              setIsOpenMainSidebar={setIsOpenMainSidebar}
              width="100%"
              height="100%"
            />
            <div className="order-details-div-2 p-2 m-2 flex flex-wrap justify-between items-center text-[#17202A] bg-stone-50 rounded-[10px]">
              <div className="flex justify-between items-center gap-2 w-full mb-2">
                <h2 className="font-bold text-xl uppercase text-gray-700">Logout Reports</h2>
                  <div className="flex gap-2">
                    <button
                      onClick={handlePrint}
                      className="bg-primaryLightColor25 text-primaryColor px-8 py-2 rounded-[10px] font-bold hover:bg-primaryLightColor30 flex"
                    >
                      <AiOutlinePrinter className="w-6 h-6 mx-1" />
                      Download Report
                    </button>
                    <button
                      onClick={handleSoldPrint}
                      className="bg-primaryLightColor25 text-primaryColor px-8 py-2 rounded-[10px] font-bold hover:bg-primaryLightColor30 flex"
                      disabled = {isDisabled}
                    >
                      <AiOutlinePrinter className="w-6 h-6 mx-1" />
                      {isDisabled ? "Please Wait..." : "Download sold Product Report"}
                    </button>

                  </div>

              </div>
              {/* <div className="logout-order-container m-[5px]">
                <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 mb-5">
                  <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                        Total Cash Collection
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_cash_collection || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
                    </div>
                  </div>


                  <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                        Cash from cash sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_cash_from_cash_sales || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                        Cash from wallet additions
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_cash_from_wallet_add || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                        Cash from card + cash
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_cash_from_card_Plus_cash || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                        Cash from wallet + cash
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_cash_from_wallet_Plus_cash || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                        Petty Cash
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].petty_cash || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
                    </div>
                  </div>


                </div>

                <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 mb-5">

                  <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                        Total Digital Sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_digital_collection || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                        Card Sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_digital_from_card_sales || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                        Card+Cash Sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_digital_from_card_Plus_cash || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                        Wallet Addition
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_digital_from_wallet_add || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                        Wallet+Card
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_digital_from_wallet_Plus_card || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
                    </div>
                  </div>

                </div>

                <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 mb-5">

                  <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                        Total Wallet Collection
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_wallet_collection || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
                    </div>
                  </div>


                  <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                        Total Wallet Spend
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_wallet_spnd_collection || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                        Wallet Spend from Wallet Sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_wallet_spnd_from_wallet_sales || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
                    </div>
                  </div>


                  <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                        Wallet + Cash Spend
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_wallet_spnd_from_wallet_Plus_cash
                          || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
                    </div>
                  </div>


                  <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                        Card + Wallet Spend
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_wallet_spnd_from_card_Plus_wallet || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
                    </div>
                  </div>


                </div>


                <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 mb-5">

                  <div className="bg-completeLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-completeColor relative overflow-hidden shadow shadow-completeLightColor hover:shadow-lg hover:shadow-completeLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-completeColor font-bold text-2xl uppercase leading-none mb-2">
                        Digital Account Sale
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_account_sale || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-completeColor hover:opacity-100" />
                    </div>
                  </div>


                  <div className="bg-completeLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-completeColor relative overflow-hidden shadow shadow-completeLightColor hover:shadow-lg hover:shadow-completeLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-completeColor font-bold text-2xl uppercase leading-none mb-2">
                        Delivery Charge
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_account_delivery_charges || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-completeColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-completeLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-completeColor relative overflow-hidden shadow shadow-completeLightColor hover:shadow-lg hover:shadow-completeLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-completeColor font-bold text-2xl uppercase leading-none mb-2">
                        Sales as per Delivery
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].sales_per_delievery || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-completeColor hover:opacity-100" />
                    </div>
                  </div>


                </div>

                <div className="grid lg:grid-cols-1 md:grid-cols-1 gap-4 mb-5">

                  <div className="bg-onholdLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-onholdColor relative overflow-hidden shadow shadow-onholdLightColor hover:shadow-lg hover:shadow-onholdLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-onholdColor font-bold text-2xl uppercase leading-none mb-2">
                        Net Sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].netsales || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-onholdColor hover:opacity-100" />
                    </div>
                  </div>


                </div>




              </div> */}

              <div className="logout-order-container m-[5px] px-3">
                <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 mb-5">
                  <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                        Total Cash Collection
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_cash_collection || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
                    </div>
                  </div>


                  <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                        Sole cash sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].sole_cash_sales || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                        cash + wallet
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].cash_plus_wallet || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                        cash + card
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].cash_plus_card || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                        Wallet additions through cash
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].wallet_add_cash || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-finalLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-finalColor relative overflow-hidden shadow shadow-finalLightColor hover:shadow-lg hover:shadow-finalLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-finalColor font-bold text-2xl uppercase leading-none mb-2">
                        Petty cash in begining
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].petty_cash || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-finalColor hover:opacity-100" />
                    </div>
                  </div>


                </div>

                <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 mb-5">

                  <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                        total digital collection
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_digital_collection || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                        sole card sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].sole_card_sales || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                        card + cash
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].card_plus_cash || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                        card + wallet
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].card_plus_wallet || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-initLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-initColor relative overflow-hidden shadow shadow-initLightColor hover:shadow-lg hover:shadow-initLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-initColor font-bold text-2xl uppercase leading-none mb-2">
                        Wallet additions through card
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].wallet_add_card || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-initColor hover:opacity-100" />
                    </div>
                  </div>

                </div>

                <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 mb-5">

                  <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                        Total Wallet Collection
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_wallet_collection || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
                    </div>
                  </div>


                  <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                        Wallet addition through cash
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].wallet_add_cash || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                        Wallet addition through card
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].wallet_add_card || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
                    </div>
                  </div>


                  <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                        Total wallet deduction/sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_wallet_deduction_sales
                          || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
                    </div>
                  </div>


                  <div className="bg-revenueLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-revenueColor relative overflow-hidden shadow shadow-revenueLightColor hover:shadow-lg hover:shadow-revenueLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-revenueColor font-bold text-2xl uppercase leading-none mb-2">
                        Total delivery partner sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_delievery_partner_sales || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-revenueColor hover:opacity-100" />
                    </div>
                  </div>


                </div>


                <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 mb-5">
                  <div className="bg-completeLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-completeColor relative overflow-hidden shadow shadow-completeLightColor hover:shadow-lg hover:shadow-completeLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-completeColor font-bold text-2xl uppercase leading-none mb-2">
                        total sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].total_sales || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-completeColor hover:opacity-100" />
                    </div>
                  </div>


                  <div className="bg-completeLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-completeColor relative overflow-hidden shadow shadow-completeLightColor hover:shadow-lg hover:shadow-completeLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-completeColor font-bold text-2xl uppercase leading-none mb-2">
                        Delivery Charge
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].delievery_charges || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-completeColor hover:opacity-100" />
                    </div>
                  </div>

                  {/* <div className="bg-completeLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-completeColor relative overflow-hidden shadow shadow-completeLightColor hover:shadow-lg hover:shadow-completeLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
              <div>
                <h3 className="text-completeColor font-bold text-2xl uppercase leading-none mb-2">
                  petty cash
                </h3>
                <span className="text-black/70 font-bold text-2xl">
                  &#8377;
                </span>
                <CountUp
                  delay={1}
                  decimals={2}
                  end={singleLogoutReport[0].petty_cash || "0"}
                  className="text-black/70 font-bold text-2xl"
                />
              </div>
              <div>
                <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-completeColor hover:opacity-100" />
              </div>
            </div> */}


                </div>

                <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 mb-5">

                  <div className="bg-onholdLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-onholdColor relative overflow-hidden shadow shadow-onholdLightColor hover:shadow-lg hover:shadow-onholdLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-onholdColor font-bold text-2xl uppercase leading-none mb-2">
                        Net Total Pos Sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].net_total_pos_sales || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-onholdColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-onholdLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-onholdColor relative overflow-hidden shadow shadow-onholdLightColor hover:shadow-lg hover:shadow-onholdLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-onholdColor font-bold text-2xl uppercase leading-none mb-2">
                        Net Total Pos cash Sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].net_total_pos_cash_sales || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-onholdColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-onholdLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-onholdColor relative overflow-hidden shadow shadow-onholdLightColor hover:shadow-lg hover:shadow-onholdLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-onholdColor font-bold text-2xl uppercase leading-none mb-2">
                        Net Digital Sales Collection
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].net_digital_sales_collection || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-onholdColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-onholdLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-onholdColor relative overflow-hidden shadow shadow-onholdLightColor hover:shadow-lg hover:shadow-onholdLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-onholdColor font-bold text-2xl uppercase leading-none mb-2">
                        Net status of wallet
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].net_status_of_wallet || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-onholdColor hover:opacity-100" />
                    </div>
                  </div>

                  <div className="bg-onholdLightColor px-4 py-3 rounded-[10px] flex justify-between items-center border-l-4 border-onholdColor relative overflow-hidden shadow shadow-onholdLightColor hover:shadow-lg hover:shadow-onholdLightColor hover:translate-y-[-3px] duration-300 ease-in-out ">
                    <div>
                      <h3 className="text-onholdColor font-bold text-2xl uppercase leading-none mb-2">
                        Net Total Delivery Sales
                      </h3>
                      <span className="text-black/70 font-bold text-2xl">
                        &#8377;
                      </span>
                      <CountUp
                        delay={1}
                        decimals={2}
                        end={singleLogoutReport[0].net_total_delievery_sales || "0"}
                        className="text-black/70 font-bold text-2xl"
                      />
                    </div>
                    <div>
                      <CurrencyRupeeIcon className="w-16 h-16 opacity-20  absolute right-[-16px] top-[10px] hover:text-onholdColor hover:opacity-100" />
                    </div>
                  </div>


                </div>

              </div>

            </div>


          </div>
        </section>
      )}
    </>
  );
};

export default connect(null, { showLoader, hideLoader })(
  LogoutReportsDetailsDisplay
);
